header {
	.upper {
		.bar {
			display: table;
			width: 100%;
			
			.row {
				display: table-row;
				
				& > * {
					display: table-cell;
					vertical-align: middle;
				}
				
				.logo, .menu-holder {
					padding: 5px 30px;
					width: 100px;
					white-space: nowrap;
					.big-phone-and-smaller({
						padding: 5px 10px;
						img {
							max-width: 180px;
						}
					});
				}
				
				.logo {
					width: 95%;
				}
				
				.pay-rent {
					.fs(14);
					text-decoration: none;
					color: @grey-body-text;
					font-weight: 600;
					white-space: nowrap;
					
					&:hover {
						text-decoration: underline;
					}
				}
				
				.menu-holder {
					text-align: right;
					.tribar {
						position: relative;
						width: 30px;
						margin: 0 10px 0 15px;
						cursor: pointer;
						vertical-align: middle;
						display: inline-block;
						z-index: 12;
						
						.one, .two, .three {
							border-bottom: solid 3px;
							border-color: @grey-light-text;
							transition: transform .4s;
						}
						.two {
							margin: 7px 0;
						}
					}
				}
				
				.contact {
					text-align: center;
				}
				
				.phones, .emails, .person {
					display: inline-block;
				}
				
				.phones, .emails {
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}
	}
}

.projects {

	.top {
		.filter {
			float: right;
			cursor: pointer;
		}
		
	}
	
	.project-row {
		position: relative;
		overflow-x: hidden;
		overflow-y: hidden;
		clear: both;
	}
	
	.project-row-inner {
		white-space: nowrap;
	}
	
	.project {
		display: none;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		width: 40%;
		margin-right: 40px;
		
		&:before {
			content: '\00a0';
			font-size: 0;
			display: inline-block;
			padding-top: 75%;
		}
		
		&.show {
			display: inline-block;
			position: relative;
		}
		
		.role {
			font-size: 70%;
			font-style: italic;
			margin-top: 20px;
		}
		
		.mask {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0;
			background-color: fade(black, 60%);
			transition: opacity .3s;
			
			&:before {
				content: '\00a0';
				display: inline-block;
				padding-top: 37.5%;
				padding-bottom: 37.5%;
				width: 0;
			}
			
			.inner {
				display: inline-block;
				box-sizing: border-box;
				width: 95%;
				vertical-align: middle;
				text-align: center;
				padding: 10px;
				color: white;
				text-decoration: none;
				.fs(40);
				font-weight: 600;
				white-space: normal;
			}
		}
		
		&:hover {
			.mask {
				opacity: 1;
			}
		}
		
		.tablet-and-smaller({
			width: 100%;
			
			.mask {
				opacity: 1;
				
				.inner {
					box-sizing: border-box;
					padding: 0 5px;
					width: 95%;
				}
			}
		});
	}
	
	.big-phone-and-smaller({
		.top {
			.filter {
				float: none;
			}
		}
	});
}

.purple-bar {
	background-color: @purple-north-rock;
	
	.make-me-a-table;
	
	.table {
		width: 100%;
	}
	
	.row > * {
		vertical-align: middle !important;
		padding: 20px 0;
	}
	
	.button {
		width: 0;
		text-align: right;
		a {
			.orange-wrap-button;
			display: inline-block;
		}
	}
	
	h1 {
		color: white;
		padding-left: 60px;
		position: relative;
		
		&:before {
			content: '\00a0';
			height: 0;
			border-bottom: 2px solid @orange-north-rock;
			position: absolute;
			top: 50%;
			left: 0;
			width: 50px;
		}
	}
	
	.tablet-and-smaller({
		.table .row > * {
			text-align: left;
			margin: 0;
		}
	});
}

.orange-wrap-button {
	border: solid 2px @orange-north-rock;
	color: white;
	padding: 15px 20px;
	background: none;
	font-weight: bold;
	text-decoration: none;
	
	&:hover {
		border-color: white;
		color: @orange-north-rock;
	}
}

.special-section-fonts {
	h1 {
		color: @orange-north-rock;
		.fs(16);
		line-height: 130%;
		margin-bottom: 15px;
		text-transform: uppercase;
	}
	
	h2 {
		.fs(36);
		color: @purple-north-rock;
		font-weight: 600;
	}
	
	h1 + h2 {
		margin-top: 0;
	}

}

p, li {
	line-height: 1.5em;
}


.side-by-text-and-pic {
	.make-me-a-table;
	
	.txt {
		padding-right: 50px;
		
		.big-phone-and-smaller({
			padding-right: 0;
		})
	}
	.pic-holder {
		position: relative;
		padding: 0 0 70px 70px;
		
		img {
			max-width: 500px;
			position: relative;
			z-index: 2;
			width: 100%;
		}
		
		&:before {
			content: '\00a0';
			background-image: url('/img/dots.png');
			position: absolute;
			bottom: 0;
			left: 0;
			height: 60%;
			width: 80%;
			background-repeat: repeat;
		}
	}
	
	.pic {
		width: 45%;
	}
	
}

.three-link {
	.container {
		position: relative;
		
		.link-row {
			margin-left: -2%;
			margin-right: -2%;
			.clear-inner;
			
			
			.link {
				position: relative;
				display: inline-block;
				box-sizing: border-box;
				float: left;
				width: 29.333%;
				margin: 0 2%;
				border: solid 2px @orange-north-rock;
				
				&:before {
					content: '\00a0';
					padding-top: 42%;
					display: inline-block;
					font-size: 0;
				}
				
				& > a {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					padding: 12px;
					text-decoration: none;
					
					.title {
						.fs(29);
						display: block;
						font-weight: 600;
						color: @purple-north-rock;
						height: 3em;
						padding-top: 9px;
					}
					
					.learn {
						color: @grey-body-text;
						.fs(19);
					}
				}
				
			}
			.tablet-and-smaller({
				text-align: center;
				.link {
					float: none;
					display: block;
					width: auto;
					max-width: 400px;
					margin: 20px auto;
					text-align: left;
				}
			});
			
		}
	}
}

.our-team .persons {
	.make-me-a-table;
	
	.profile-pic {
		background-color: @grey-person-background;
		background-image: url('/img/staff-placeholder.png');
		background-position: center;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		width: 300px;
		
	}
	
	.person {
		margin-bottom: 40px;
		
		.partner-contact {
			float: right;
			.fs(14);
			font-weight: 300;
			text-align: right;
			line-height: 1.6em;
			margin-top: -10px;
			margin-bottom: 20px;
			
			a {
				color: @grey-body-text;
				text-decoration: none;
				font-style: italic;
				
				&:hover {
					text-decoration: underline;
				}
			}
			
			.bar {
				padding: 0 6px;
			}
		}
		
		.position, .name {
			float: left;
			clear: left;
		}
		
		.name {
			padding-top: 10px;
		}
		
		.position {
			text-transform: uppercase;
		}
		
		.name {
			margin-bottom: 20px;
		}
		
		.desc {
			clear: both;
		}
	}
	
	.profile-txt {
		padding: 20px;
		background-color: @grey-info-background;
		
		.position {
			color: @orange-north-rock;
			.fs(16);
			margin-bottom: 0;
			font-weight: 600px;
		}
		
	 	.name {
			.fs(36);
			color: @purple-north-rock;
			font-weight: 600;
			p {
				margin: 0;
			}
		}
		
		.postion + .name {
			margin-top: 0;
		}

	}
}

.four-things-to-say {
	margin: 50px 0;
	.intro {
		h1 {
			text-transform: uppercase;
			color: @orange-north-rock;
			.fs(25);
			margin-bottom: 50px;
			font-weight: 600;
			
			strong {
				.fs(36);
				color: @purple-north-rock;
			}
		}
	}
	.things-row {
		margin-left: -2%;
		margin-right: -2%;
		.clear-inner;
		
		.thing {
			margin: 0 2%;
			box-sizing: border-box;
			float: left;
			width: 21%;
			
			h2 {
				color: @orange-north-rock;
				.fs(24);
			}
			
			ul {
				padding: 0;
				margin: 0;
				list-style: none;
				
				ul {
					padding-left: 15px;
				}
			}
			
			li {
				color: @grey-light-text;
				.fs(14);
				padding: 6px 0;
			}
		}
		
		.tablet-and-smaller ({
			.thing {
				width: 46%;
				
				&:nth-child(3)  {
					clear: both;
				}
			}
		});
		.big-phone-and-smaller ({
			.thing {
				width: 96%;
			}
		});
	}
	
}

.dash-list {
	list-style: none;
	li {
		padding: 5px 0;
		&:before {
			content: '- ';
		}
	}
}


.press-grid {
	margin: 60px 0;
	overflow: hidden;
	
	.intro {
		h1 {
			text-transform: uppercase;
			color: @orange-north-rock;
			.fs(16);
			
			strong {
				.fs(36);
				color: @purple-north-rock;
			}
		}
	}
	.things-row {
		margin-left: -2%;
		margin-right: -2%;
		.clear-inner;
		
		.thing {
			margin: 20px 2%;
			position: relative;
			box-sizing: border-box;
			float: left;
			width: 29.333%;
			border: solid 1px @grey-light-text;
			cursor: pointer;
			min-width: 250px;
			
			&:before {
				content: '\00a0';
				display: inline-block;
				font-size: 0;
				padding-top: 100%;
			}
			
			.inner {
				@off-pad: 5px;
				position: absolute;
				top: @off-pad;
				left: @off-pad;
				right: @off-pad;
				bottom: @off-pad;
				overflow: hidden;
				text-align: center;
				
				&:before {
					content: '\00a0';
					display: inline-block;
					font-size: 0;
					width: 0;
					padding-top: 100%;
					vertical-align: middle;
				}
				
				.clip-info {
					display: inline-block;
					vertical-align: middle;
					max-width: 98%;
				}
			}
			h2 {
				color: @orange-north-rock;
				.fs(24);
				font-weight: 400;
				text-transform: uppercase;
			}
			.title {
				text-transform: uppercase;
			}
			
			&:hover {
				background-color: @grey-person-background;
				color: white;
				border: 0;
				
				h2 {
					color: white;
				}
			}
		}
	}
}


.gallery-grid {
	margin: 60px 0;
	.things-row {
		margin-left: -2%;
		margin-right: -2%;
		.clear-inner;
		
		.thing {
			margin: 20px 2%;
			position: relative;
			box-sizing: border-box;
			float: left;
			width: 21%;
			background-position: center center;
			background-size: cover;
			
			&:before {
				content: '\00a0';
				display: inline-block;
				font-size: 0;
				padding-top: 100%;
			}
		}
	}
}

.thirty-years {
	margin-top: 120px;
	max-width: 100%;
}

.expando {
	margin-bottom: 30px;
	
	.opener {
		cursor: pointer;
		@box-size: 40px;
		margin-bottom: 0;
		&:before {
			content: '+';
			display: inline-block;
			background-color: @grey-plus-box;
			font-size: 30px;
			vertical-align: middle;
			margin-right: 20px;
			margin-top: -3px;
			height: @box-size;
			width: @box-size;
			text-align: center;
			line-height: @box-size;
			transition: background-color .3s;
			color: white;
		}
	}
	.view {
		overflow: hidden;
		max-height: 0px;
		position: relative;
		
		transition: max-height .3s;
		
		ul {
			list-style: none;
			padding-left: 63px;
			li {
				.fs(25);
			}
		}
		
		// &:after {
		// 	content: '\00a0';
		// 	position: absolute;
		// 	bottom: 0;
		// 	left: 0;
		// 	right: 0;
		// 	height: 30px;
		// 	.vert-back-grad(transparent, white);
		// }
	}
	
	&.open, .now-editing-page & {
		.opener {
			&:before {
				content: '-';
				background-color: @orange-north-rock;
			}

		}
		.view {
			max-height: 500px;
			
			// &:after {
			// 	display: none;
			// }
		}
	}
	
	.big-phone-and-smaller({
		.opener {
			.fs(20);
		}
		.view {
			ul {
				li {
					.fs(16);
					margin-bottom: 10px;
				}
			}
		}
	});
}
