
.setup-width-definitions() {
	@container-width:			1100px;
	@tablet-width: 				900px;
	@tablet-small-width:        716px;
	@big-phone-width:			650px;
	@phone-width:               550px;
	@iphone-4-landscape-width:	480px;
	@iphone-4-width: 			320px;
	@page-side-padding: 			10px;
	@outside-page-padding: 			58px;

}

.setup-width-definitions();


/*
 * The base font size which makes the chosen font match photoshop.
 * For Open Sans this is 7.1pt, for Source Sans Pro this is 7.6pt 
 */
 @base-font-size:			6.1pt;

 @grey-footer-line:			#e1e1e1;
 @grey-info-background:		#f2f2f2;
 @grey-person-background:	#cccccc;
 @grey-plus-box:				#9f9f9f;
 @grey-light-text:			#666666;
 @grey-body-text:			#444444;
 
 @yellow-north-rock:			#f48707;
 
 @orange-north-rock:			#f48707;
 
 @blue-box-back:				#262d7e;
 @blue-north-rock:			#002d93;
 
 @purple-north-rock:			#262d7e;
 

.source-sans-pro() {
	font-family: 'Source Sans Pro', sans-serif;
}

.open-sans() {
    font-family: 'Open Sans', sans-serif;
}

.menu-link() {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}	

.link-inherit() {
	a {
		.menu-link;
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		font-family: inherit;
	}
}







.exo {
	font-family: 'Exo 2', sans-serif;
}

.titillium {
	font-family: 'Titillium Web', sans-serif;
}

.source-sans-pro {
	font-family: 'Source Sans Pro', sans-serif;
}

.open-sans {
    font-family: 'Open Sans', sans-serif;
}

.montserrat, .gotham {
	font-family: 'Montserrat', sans-serif;
}


h1, h2, h3, h4, h5 {
	.link-inherit;
	.gotham;
}

body {
	.gotham;
    color: @grey-body-text;
}

.yellow {
	color: @yellow-north-rock;
}
