
.newpage {
	


	.std-header {
		color: white;
		background-color: black;

		padding: 20px; 
		.tablet({
			padding: 20px @outside-page-padding 20px @outside-page-padding;

		});
		
		.logo {
			color: white;
			text-decoration: none;
			img {
				max-width: 80%;
			}
		}
		
		.menu {
			list-style: none;
			li {
				margin-left: 10px;
				padding-bottom: 10px;
				a {
					.fs(20);
					color: white;
					text-transform: uppercase;
					text-decoration: none;
					font-weight: 800;
					padding-left: 10px;
					padding-right: 10px;
					padding-bottom: 6px;	
					padding-top: 2px;
				}
			}
		}

		.desktop({
			display: grid;
			grid-template-columns: auto 1fr;
		
			nav {
				justify-self: right;
				align-self: center;
				text-align: right;

			}
			.menu {
				display: inline-block;
				margin: 0;
				padding: 0;
				li {
					display: inline-block;
					a {
					}
					
					&.current {
						border-bottom: solid 2px white;
					}
					
				}
			}
			.tribar {
				display: none;
			}
		});
		.desktop-and-smaller({
			nav {
				position: fixed;
				width: 100%;
				width: 100vw;
				height: 100%;
				height: 100vh;
				z-index: 5;
				top: 0;
				left: -20000px;
				max-width: 350px;
				opacity: 0;
				transition: opacity .3s;
				overflow: auto;
				padding: 50px 15px 0 15px;
				box-sizing: border-box;
				background-color: black; 
				.menu {
					padding: 0;
				}
				
				& > li {
					display: block;
					text-align: center;
					
					& > a {
						text-align: center;
						font-weight: 600;
					}
					
					& > ul {
						position: relative;
						left: auto;
						opacity: 1;
						text-align: center;
						
						a {
							text-align: center;
						}
					}
				}
				
				&.open {
					left: 0;
					opacity: 1;
				}
			}

			.tribar {
				width: 30px;
				margin: 10px 10px 0 0;
				cursor: pointer;
				position: absolute;
				top: 10px;
				right: 10px;
				
				.one, .two, .three {
					border-bottom: solid 3px;
					border-color: white;
				}
				.two {
					margin: 7px 0;
				}
				
			}
		});

	}

	header {
		.std-header;
	}
}

.page {
	margin-left: auto;
	margin-right: auto;
	max-width: 1920px;
	
}

.landing-page {
	background-color: white;
	padding-bottom: 50px;
	box-sizing: border-box;
	overflow: hidden;
	
	text-align: center;
	
	.logo {
		padding: 100px 0;
		max-width: 95%;
	}
	
	.nameplate {
		.exo;
		.fs(120);
		font-weight: 800;
		text-transform: uppercase;
		margin: 0 0 50px 0;
		color: @blue-north-rock;
	}
	.coming {
		.exo;
		text-transform: uppercase;
		.fs(30);
		font-weight: 300;
	}
	
	.boxes {
		font-size: 0;
		text-align: center;
		color: @grey-body-text;
	}
	
	.box {
		.montserrat;
		display: inline-block;
		box-sizing: border-box;
		border: solid 1px @grey-body-text;
		margin: 30px 1%;
		width: 31%;
		padding: 10px;
		vertical-align: top;
		
		img {
			max-width: 100%;
		}
		
		.type {
			.gotham;
			.fs(16);
			text-transform: uppercase;
			padding: 20px 0;
		}
		
		.desc {
			height: 3em;
			.fs(14);
		}
		
		a {
			text-decoration: none;
			color: inherit;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	
	.desktop-and-smaller({
		.nameplate {
			font-size: 10vw;
		}
	});
	
	.big-phone-and-smaller({
		.box {
			display: block;
			margin: 30px auto;
			width: auto;
			max-width: 350px;
		}
	});
}


@banner-height: 75vh;
.slide-banner {
	height: @banner-height;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
	// background-image: url('/img/north-rock-slide-1.jpg');
	position: relative;
	background-color: black;
	
	&:before {
		content: '\00a0';
		display: inline-block;
		width: 0;
		line-height: @banner-height;
		vertical-align: middle;
	}
	
	.txt {
		display: inline-block;
		vertical-align: middle;
	}
	.slide {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		text-align: center;
		transition: opacity .5s;
		opacity: 0;
		text-align: left;
		display: none;
		
		&:first-of-type {
			z-index: 0;
		}
		
		&:before {
			content: '\00a0';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: fade(black, 50%);
			z-index: -1;
		}
		
		&.visible {
			opacity: 1;
			z-index: 1;
			display: block;
		}
		&.invisible {
			z-index: 0;
			display: block;
		}
		
		a {
			color: white;
			text-decoration: none;
		}
		
		.container {
			height: 100%;
			overflow: hidden;
			
			&:before {
				content: '\00a0';
				height: 100%;
				display: inline-block;
				width: 0;
				vertical-align: middle;
			}
			
			.msg {
				display: inline-block;
				vertical-align: middle;
				border-style: solid;
				border-width: 2px 0 2px 2px;
				border-color: @orange-north-rock;
				box-sizing: border-box;
				width: 240px;
				color: white;
				
				&:before {
					content: '\00a0';
					height: 60px;
					display: block;
					position: relative;
					border-right: solid 2px @orange-north-rock;
					margin-bottom: 20px;
				}
				
				&:after {
					content: '\00a0';
					height: 60px;
					display: block;
					position: relative;
					border-right: solid 2px @orange-north-rock;
					margin-top: 20px;
				}
				
				.header {
					position: absolute;
					padding-left: 40px;
					top: 30%;
					.fs(19);
					white-space: nowrap;
					margin-left: -5px;
				}
				
				.main {
					padding-left: 70px;
					.fs(58);
					font-weight: 600;
					white-space: nowrap;
					margin-left: -5px;
					box-sizing: border-box;
					
					strong {
						color: @orange-north-rock;
					}
				}
				
				.footer {
					position: absolute;
					top: 75%;
					padding-left: 40px;
					white-space: nowrap;
				}
				
				.small-tablet-and-smaller({
					border-color: transparent;
					width: 95%;
					height: auto;
					
					&:before, &:after {
						display: none;
					}
					
					.header {
						position: relative;
						top: auto;
						padding: 30px 0;
					}
					.main {
						position: relative;
						
						padding-left: 0;
						top: auto;
						font-size: 5vw;
						white-space: normal;
					}
					.footer {
						top: auto;
						position: relative;
						padding: 30px 0;
						
					}
				});
			}
			
		}
		
	}
	
	&.no-box {
		.slide {
			.container {
				.msg {
					border: 0;
					&:before, &:after {
						display: none;
					}
				}
			}
		}
	}
	.indicators {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 50px;
		text-align: center;
		z-index: 5;
		.dots {
			margin: 25px 0;
		}
		
		.up, .down {
			cursor: pointer;
		}
		
		.dot {
			display: inline-block;
			border-radius: 50%;
			width: 10px;
			height: 10px;
			background-color: white;
			margin: 15px 0;
			
			&.current {
				background-color: @yellow-north-rock;
			}
		}
		
		.big-phone-and-smaller({
			right: 15px;
		});
	}
	
}

.page-index {
	header {
	}
}

.page {
	overflow: hidden;
}

header {
	.tablet-and-smaller({
		.upper .bar .row .contact {
			display: none;
		}
	});
}

.page-inner {
	header {
		@banner-height: 305px;
		.banner {
			height: @banner-height;
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
			
			
			&:before {
				content: '\00a0';
				display: inline-block;
				width: 0;
				line-height: @banner-height;
				vertical-align: middle;
			}
			
			.txt {
				display: inline-block;
				vertical-align: middle;
				text-align: center;
				width: 98%;
			}
			h1 {
				display: inline-block;
				color: white;
				.fs(58);
				text-transform: uppercase;
				font-weight: 600;
				padding: 23px 30px;
				border: solid 1px @orange-north-rock;
				margin-left: 15px;
				margin-right: 15px;
				
				.big-phone-and-smaller({
					.fs(30);
				});
			}
		}
		
	}
}

header {
	.menu-holder {
		cursor: pointer;
		
	}
	.menu-holder img {
		position: relative;
		z-index: 20;
	}
	.menu {
		opacity: 0;
		transition: opacity .3s, transform .3s;
		position: fixed;
		top: 0;
		left: 100%;
		height: 100vh;
		background-color: white;
		z-index: -1;
		transform: translateX(0);
		min-width: 320px;
		overflow: auto;
		
		.contact {
			text-align: left;
			padding: 0 15px;
			strong {
				display: block;
				padding-bottom: 6px;
			}
			
			.person {
				padding: 6px 0;
			}
		}
		
		.menu-open & {
			opacity: 1;
			z-index: 10;
			text-align: center;
			transform: translateX(-100%);
			text-align: left;
			padding-left: 50px;
			width: 667px;
			max-width: 100%;
			
			
			
			.scroller {
				position: relative;
				margin-left: 50px;
			
				& > ul {
					padding-bottom: 150px;
					
					.phone-and-smaller({
						padding-bottom: 200px;
					});
				}
				& > ul > li > a {
					font-weight: 600;
				}
				
				& > ul > li > ul {
					padding-left: 10px;
					display: block;
					a {
						.fs(30);
						font-weight: 400;
					}
				}
				
				ul {
					display: inline-block;
					list-style: none;
					vertical-align: middle;
					text-align: left;
					padding-top: 1vh;
					padding-left: 0;
					
					img {
						padding-bottom: 20px;
						padding-top: 20px;
					}
					a {
						.fs(36);
						color: @grey-light-text;
						text-decoration: none;
						font-weight: 600;
						
						&:hover {
							color: black;
						}
					}
				}
				
				li {
					padding: 1vh 1vh 1vh 0;
				}
				
				ul li a {
					&:hover, &.current {
						color: @yellow-north-rock;
					}
				}
			}
		}
		
		.contact {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			padding-left: 0;
			
			.person {
				display: inline-block;
				padding-right: 15px;
				strong {
					color: black;
					font-weight: 600;
					display: inline-block;
				}
				.name {
					.fs(16);
				}
				.phone {
					.fs(16);
				}
			}
		}
		
		.copyright {
			padding-top: 70px;
			text-align: left;
			.fs(14);
		}
		
	}
}



body.menu-open {
	overflow: hidden;
	
	.tribar {
		position: fixed !important;
		top: 20px;
		right: 20px;
	}
	.one {
		transform: rotate(45deg) translate(6px, 11px)
	}
	
	.three {
		transform: rotate(-45deg) translate(2px, -8px)
	}
	
	.two {
		opacity: 0;
	}
}

.projects {
	padding: 44px 30px 30px 30px;
	position: relative;
	
	
	
	.project-show {
		display: table;
		width: 100%;
		margin-top: 40px;
		
		.project-show-row {
			display: table-row;
			
			& > * {
				display: table-cell;
				vertical-align: middle;
			}
			
			.center {
				width: 100%;
			}
			
			.project:first-of-type {
				transition: margin-left .3s;
			}
		}
		
		.left, .right {
			cursor: pointer;
			img {
				max-width: 5vw;
			}
		}
		.left {
			padding-right: 6px;
		}
		.right {
			padding-left: 6px;
		}
	}
	
	.big-phone-and-smaller({
		padding-left: 10px;
		padding-right: 10px;
		font-size: 0;
		
		.project-show {
			display: block;
			
			.project-show-row {
				display: block;
				
				& > * {
					display: inline-block;
					vertical-align: middle;
					box-sizing: border-box;
				}
				
				.left, .right {
					width: 10%;
				}
				
				.center {
					width: 80%;
				}
				
			}
		}
		.property-popup {
			font-size: 16px;
		}
		
	});
}

.intro-vid {
	text-align: center;
	margin-top: 80px;
	margin-bottom: 40px;
	
	.project-controls {
		margin-bottom: 40px;
	}
	video {
		max-width: 100%;
		width: 1280px;
	}
}

.we-lead {
	padding-top: 30px;
	.side-by-text-and-pic;
	.special-section-fonts;
	padding-bottom: 10px;
	
	.txt {
		h1 {
			margin-top: 0;
		}
	}
}

.what-we-do {
	.special-section-fonts;
	.three-link;
	.container .link-row .link:hover {
		background-color: @blue-box-back;
		border: solid 2px @blue-box-back;
		
		span { 
			color: white;
		}
		
	}
	
	h1 {
		margin-bottom: 37px;
	}
	
	.link {
		width: 46% !important;
		margin-top: 30px !important;
	}

}

.our-team {
	margin-top: 60px;
	.special-section-fonts;
	
	h1 {
		margin-bottom: 37px;
	}

}

section.contact {
	.special-section-fonts;
	.make-me-a-table;
	margin-bottom: 60px;
	
	.contact-info-row {
		margin-top: 60px;
		.type {
			.fs(24);
			color: @purple-north-rock;
			text-transform: uppercase;
			font-weight: 600;
		}
		.info {
			color: @grey-light-text;
		}
		h4 {
			.fs(20);
			color: @grey-body-text;
			margin-bottom: 0;
			margin-top: 0;
		}
	}
}

footer {
	position: relative;
	.make-me-a-table;
	padding-top: 75px;
	line-height: 2em;
	
	a {
		.fs(14);
		text-decoration: none;
		color: inherit;
		&:hover {
			color: @yellow-north-rock;
		}
	}
	
	.row > * {
		vertical-align: top;
		width: 0;
	}
	
	.row > *:first-child {
		padding-right: 20px;
	}	
	
	// .row > *:last-child {
	// 	text-align: right;
	// }
	
	.contact-block {
		padding-top: 20px;
		white-space: nowrap;
		.office {
			display: inline-block;
			text-align: left;
			line-height: 1.6em;
		}
		
		.office:first-child {
			padding-right: 30px;
		}
	}
	
	.menu-block {
		padding-top: 11px;
		
	}
	
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		vertical-align: top;
		
		ul {
			display: none;
		}
		
		li {
			display: inline-block;
			padding: 0 6px;
			position: relative;
			
			&:before {
				content: '|';
				position: absolute;;
				margin-left: -10px;
				font-size: 80%;
				top: 1px;
			}
		}
		
		li:first-child {
			&:before {
				display: none;
			}
		}
	}
	
	.menu, .menu-block {
		img, br {
			display: none;
		}
	}
	
	.menu-block {
		width: 100%;
		padding-right: 15px;
	}
	
	&:before {
		content: '\00a0';
		position: absolute;
		top: 0;
		left: 15px;
		right: 15px;
		border-top: solid 1px @grey-footer-line;
	}
	
	// .contact {
	// 	display: none !important;
	// }
	
	.logo-link {
		display: none;
	}
	
	.container {
		padding-bottom: 45px;
	}
	
	.contact-block {
		.fs(14);
		display: inline-block;
	}
	
	.copyright {
		.fs(14);
		text-align: center;
		background-color: @grey-info-background;
		padding: 10px;
	}
	
	.tablet-and-smaller({
		.row > * {
			margin-bottom: 0 !important;
		}
		
		.contact-block {
			white-space: normal;
		}
	});
}

.contact-info-row {
	a {
		color: inherit;
		text-decoration: none;
		
		&:hover {
			color: black;
		}
	}
}

.main-content {
	margin-top: 60px;
	.shortened {
		max-width: 800px;
	}
	h1 {
		color: @purple-north-rock;
		.fs(30);
		font-weight: 600;
	}
	p {
		.fs(18);
		color: @grey-light-text;
	}
}

.project-controls {
	display: table;
	width: 100%;
	
	.row {
		display: table-row;
		
		& > * {
			display: table-cell;
			vertical-align: middle;
			text-align: center;
		}
	}
	
	.section-label {
		.fs(36);
		color: @purple-north-rock;
		text-align: left;
		font-weight: 600;
	}
	
	.filter {
		a {
			padding: 0 10px;
			color: @grey-body-text;
			font-weight: 600;
			.fs(18);
			
			&.current, &:hover {
				color: @yellow-north-rock;
			}
			
			&:first-child {
				padding-left: 0;
			}
		}
	}
	
	.big-phone-and-smaller({
		display: block;
		.row {
			display: block;
			
			& > * {
				display: block;
				text-align: left;
				margin-top: 30px;
			}
		}
	});
}

.page-property-management {
	.main-content {
		padding-bottom: 150px;
	}
}

.property-popup {
	position: absolute;
	background-color: white;
	
	top: 87px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	background-position: center center;
	background-size: cover;
	
	video {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	
	.info-box {
		position: absolute;
		top: 40px;
		left: 120px;
		bottom: 40px;
		background-color: white;
		max-width: 80%;
		width: 530px;
		box-sizing: border-box;
		padding: 20px 40px;
		z-index: 2;
		overflow: auto;
		
		h1 {
			margin-top: 0;
		}
		
		.hide-info, .show-info {
			text-transform: uppercase;
			font-weight: 600;
			cursor: pointer;
		}
		
		.long-description {
			// height: 120px;
			overflow: auto;
		}
		
		.props {
			margin: 20px 0;
		}
		
		.prop {
			padding: 10px 0;
			.clear-inner;
			border-bottom: solid 1px @grey-footer-line;
			
			.name {
				float: left;
				width: 35%;
				font-weight: 600;
			}
			.value {
				float: left;
				width: 65%;
				text-align: right;
			}
			
			&:last-of-type {
				border-bottom: 0;
			}
		}
		
		.share {
			position: absolute;
			bottom: 20px;
			left: 40px;
			right: 40px;
			.clear-inner;
			white-space: nowrap;
			text-align: right;
			
			.icons {
				display: inline-block;
				a {
					padding: 0 7px;
					
					.orange {
						display: none;
					}
					
					&:hover {
						.black {
							display: none;
						}
						.orange {
							display: inline-block;
						}
					}
				}
			}
		}
	}
	
	.close-x {
		padding: 0 25px;
		background-color: rgba(0, 0, 0, .3);
		color: white;
		font-size: 90px;
		position: absolute;
		z-index: 2;
		top: 100px;
		right: 100px;
		text-transform: uppercase;
		font-weight: 100;
	}
	
	.close {
		cursor: pointer;
	}
	
	.slide {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		background-position: center center;
		background-size: cover;
		transition: opacity .5s;
		
		&.visible {
			opacity: 1;
		}
	}
	
	.prev {
		position: absolute;
		top: 50%;
		left: 30px;
		transform: translateY(-50%);
		z-index: 2;
		cursor: pointer;
		max-height: 20%;
	}
	.next {
		position: absolute;
		top: 50%;
		right: 30px;
		transform: translateY(-50%);
		z-index: 2;
		cursor: pointer;
		max-height: 20%;
	}
	
	.show-info {
		display: none;
	}
	
	.info-content {
		position: absolute;
		top: 50px;
		bottom: 50px;
		left: 40px;
		right: 0px;
		padding-right: 40px;
		overflow: auto;
	}
	
	
	&.rollup {
		.info-box {
			bottom: auto;
		}
		.show-info {
			display: block;;
		}
		.hide-info {
			display: none;
		}
		.info-content {
			height: 0;
			overflow: hidden;
		}
		.share {
			display: none;
		}
	}
	
	.desktop-and-smaller({
		
		position: relative;
		left: auto;
		right: auto;
		
		.info-box {
			position: relative;
			width: auto;
			top: auto;
		    left: auto;
		    right: auto;
			bottom: auto;
			padding: 0;
			max-width: none;
			.long-description {
				height: auto;
			}
			.share {
				bottom: 0;
			}
		}
		
		.info-content {
			position: relative;
			top: auto;
			bottom: auto;
			left: auto;
			right: auto;
			padding-right: 0px;
			overflow: auto;
		}
		
		
		.close-x {
			top: 20px;
			right: 20px;
			font-size: 30px;
		}
		.slide-area {
			position: relative;
			margin-top: 30px;
			margin-bottom: 60px;
			
			&:before {
				content: '\00a0';
				display: inline-block;
				font-size: 0;
				padding-top: 60%;
			}
		}
	});
}

.we-lead .stats {
	margin-left: 50px;
	list-style: none;
	padding-left: 0;
	margin-top: 20px;
	margin-right: 60px;
	
	li {
		.fs(22);
		&:before {
			content: '\00a0';
			background-color: @orange-north-rock;
			vertical-align: middle;
			display: inline-block;
			width: 18px;
			height: 18px;
			margin-right: 25px;
		}
		text-indent: -44px;
		padding-left: 40px;
		margin-bottom: 1em;
		font-weight: 600;
	}
	
	.tablet-and-smaller({
		margin-right: 0;
		margin-left: 0;
	});
}

.contact-info-row {
	line-height: 1.8em;
}


.services-three-grid {
	display: table;
	width: 100%;
	
	figure {
		margin: 0;
	}
	.nowrap {
		display: inline-block;
		white-space: nowrap;
	}
	
	.row {
		display: table-row;
	}
	
	.icon-cell {
		padding-top: 15px;
		figcaption {
			display: none;
		}
	}
	
	figcaption, .label-cell {
		.fs(25);
	}
	
	.label-cell {
		h3 {
			.fs(25);
			font-weight: 400;
		}
	}
	
	.icon-cell, .label-cell {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		width: 33%;
		vertical-align: top;
		
		img {
			max-width: 100%;
			padding: 8px;
		}
	}
	
	.label-cell {
		padding: 0 7px;
	}
	
	.big-phone-and-smaller({
		text-align: center;
		
		figcaption, .label-cell {
			.fs(15);
		}
		
		
		.label-cell {
			display: none;
		}
		.row {
			display: inline;
		}
		.icon-cell {
			display: inline-block;
			margin-top: 20px;
			width: 40%;
			figcaption {
				display: block;
			}
			
			img {
				height: 100px;
			}
		}
	});
	
	.iphone-landscape-and-smaller({
		.icon-cell {
			width: auto;
			max-width: 100%;
			width: 300px;
		}		
	});
}